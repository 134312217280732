"use client";

import React from "react";
import {
  Select as NimbusSelect,
  type SelectComponents,
} from "@nimbus-ds/components";

import { NIMBUS_SELECT_ID } from "../../tailwind.config";

type Props = React.ComponentProps<typeof NimbusSelect>;

type SelectType = React.ForwardRefExoticComponent<
  Props &
    React.InputHTMLAttributes<HTMLSelectElement> &
    React.RefAttributes<HTMLSelectElement>
> &
  SelectComponents;

const Select = React.forwardRef<HTMLSelectElement, Props>((props, ref) => (
  <NimbusSelect {...props} ref={ref} data-override-id={NIMBUS_SELECT_ID} />
)) as SelectType;

Select.displayName = "Select";

Select.Option = NimbusSelect.Option;
Select.Group = NimbusSelect.Group;
Select.Skeleton = NimbusSelect.Skeleton;

export default Select;
