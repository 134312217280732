import { Divider, Tag, Thumbnail } from "~/components";

type Props = Readonly<{
  productName: string;
  variantName?: string;
  imageUrl?: string;
  conflictText: string;
}>;

export default function ProductItemWithConflict({
  productName,
  variantName,
  imageUrl,
  conflictText,
}: Props) {
  const thumbnailAlt = `${productName}${
    !!variantName ? ` (${variantName})` : ""
  }`;

  return (
    <>
      <div className="flex gap-4">
        <div className="relative self-center">
          <Thumbnail src={imageUrl} alt={thumbnailAlt} width="80px" />
        </div>

        <div className="flex flex-1 flex-col gap-2 text-base">
          <div>
            <p className="font-medium">{productName}</p>
            {variantName && <p className="font-normal"> {variantName}</p>}
          </div>
          <Tag appearance="danger">{conflictText}</Tag>
        </div>
      </div>

      <Divider />
    </>
  );
}
