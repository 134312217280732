"use client";

import React from "react";
import { Alert as NimbusAlert } from "@nimbus-ds/components";

import { NIMBUS_ALERT_ID } from "../../tailwind.config";

type Props = React.ComponentProps<typeof NimbusAlert>;

export default function Alert(props: Props) {
  return <NimbusAlert {...props} data-override-id={NIMBUS_ALERT_ID} />;
}
