"use client";

import React from "react";
import { Icon as NimbusIcon } from "@nimbus-ds/components";

type Props = React.ComponentProps<typeof NimbusIcon>;

export default function Icon(props: Props) {
  return <NimbusIcon {...props} />;
}
