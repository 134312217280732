"use client";

import { InfoCircleIcon } from "@nimbus-ds/icons";
import clsx from "clsx";

interface Props {
  message?: string;
  centered?: boolean;
}

export default function FieldErrorMessage({
  message,
  centered = false,
}: Readonly<Props>) {
  if (!message) {
    return null;
  }

  return (
    <span
      className={clsx(
        "flex items-center gap-1 text-sm text-danger-text-low",
        centered && "justify-center",
      )}
    >
      <InfoCircleIcon size={"small"} /> {message}
    </span>
  );
}
