import { type PropsWithChildren } from "react";
import { ChevronLeftIcon } from "@nimbus-ds/icons";

import { IconButton } from "~/components";

interface Props extends PropsWithChildren {
  title: string;
  backButtonLabel?: string;
  onBackButtonPress?: () => void;
}

function PageHeader({
  title,
  backButtonLabel,
  onBackButtonPress,
  children,
}: Readonly<Props>) {
  return (
    <div className="flex flex-col gap-2 sm:flex-row sm:gap-4">
      <h1 className="flex flex-1 items-center gap-2 text-3xl font-medium text-secondary-pure">
        {onBackButtonPress && (
          <IconButton
            onClick={onBackButtonPress}
            className="!h-10 !w-10"
            aria-label={backButtonLabel}
          >
            <ChevronLeftIcon size="medium" />
          </IconButton>
        )}
        {title}
      </h1>

      <div className="flex flex-col gap-4 sm:flex-row sm:justify-end">
        {children}
      </div>
    </div>
  );
}

export default PageHeader;
