"use client";

import {
  ToastProvider,
  useToast as useNimbusToast,
  type ToastProps,
} from "@nimbus-ds/components/";

import { NIMBUS_TOAST_ID } from "../../tailwind.config";

const useToast = () => {
  const {
    addToast: addNimbusToast,
    closeToast: closeNimbusToast,
    ...rest
  } = useNimbusToast();

  const addToast = (props: Omit<ToastProps, "autoClose">) =>
    addNimbusToast({
      ...props,
      //@ts-expect-error the following prop is specified to override nimbus original styles
      "data-override-id": NIMBUS_TOAST_ID,
    });

  const closeToast = (id: string) => closeNimbusToast(id);

  return { ...rest, addToast, closeToast };
};

export { ToastProvider, useToast };
