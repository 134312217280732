import { type SupportedCountryCode } from "./store";

export const SUPPORTED_LOCALES = [
  "pt",
  "es",
  "es-AR",
  "es-CL",
  "es-CO",
  "es-MX",
];

export const DEFAULT_LOCALE = "pt";

export const CountryFlagByCountryCode: Record<SupportedCountryCode, string> = {
  AR: "🇦🇷",
  BR: "🇧🇷",
  CO: "🇨🇴",
  CL: "🇨🇱",
  MX: "🇲🇽",
};

export const CountryNameByCountryCode: Record<SupportedCountryCode, string> = {
  AR: "Argentina",
  BR: "Brazil",
  CO: "Colombia",
  CL: "Chile",
  MX: "México",
};
