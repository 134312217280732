"use client";

import React, { forwardRef } from "react";
import {
  Textarea as NimbusTextarea,
  type TextareaProps,
} from "@nimbus-ds/components";

import { NIMBUS_TEXTAREA_ID } from "../../tailwind.config";

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => (
    <NimbusTextarea
      {...props}
      ref={ref}
      data-override-id={NIMBUS_TEXTAREA_ID}
    />
  ),
);

Textarea.displayName = "Textarea";

export default Textarea;
