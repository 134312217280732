"use client";

import React from "react";
import { Tag as NimbusTag } from "@nimbus-ds/components";

import { NIMBUS_TAG_ID } from "../../tailwind.config";

type Props = React.ComponentProps<typeof NimbusTag>;

export default function Tag({ children, ...props }: Props) {
  return (
    <NimbusTag {...props} data-override-id={NIMBUS_TAG_ID}>
      <div className="my-[2px] px-2 text-sm leading-[18px]">{children}</div>
    </NimbusTag>
  );
}
