"use client";

import React from "react";
import { EmptyMessage as NimbusEmptyMessage } from "@nimbus-ds/patterns";

type Props = React.ComponentProps<typeof NimbusEmptyMessage>;

export default function EmptyMessage(props: Props) {
  return <NimbusEmptyMessage {...props} />;
}
