"use client";

import type { ComponentProps } from "react";
import clsx from "clsx";

import { Link, usePathname } from "~/navigation";

type Props = Omit<ComponentProps<typeof Link>, "href"> & {
  href: string;
};

export default function NavigationLink({ href, ...rest }: Props) {
  const pathname = usePathname();
  const isActive = pathname === href;

  return (
    <Link
      aria-current={isActive}
      className={clsx(
        "inline-block px-2 py-3 transition-colors",
        isActive ? "text-white" : "text-gray-400 hover:text-gray-200",
      )}
      href={href}
      {...rest}
    />
  );
}
