"use client";

import React from "react";
import { MenuButton as NimbusMenuButton } from "@nimbus-ds/patterns";

import { NIMBUS_MENU_BUTTON_ID } from "../../tailwind.config";

type Props = React.ComponentProps<typeof NimbusMenuButton>;

export default function MenuButton(props: Props) {
  return (
    <NimbusMenuButton {...props} data-override-id={NIMBUS_MENU_BUTTON_ID} />
  );
}
