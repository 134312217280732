import { type ReactNode } from "react";
import { ExclamationCircleIcon } from "@nimbus-ds/icons";

type Props = {
  title: string;
  icon?: ReactNode;
  description?: string;
  children?: ReactNode;
};

export default function FullscreenErrorMessage({
  icon,
  title,
  description,
  children,
}: Readonly<Props>) {
  return (
    <div className="m-auto mt-20 flex max-w-4xl flex-col py-6 text-center">
      <div className="mt-6 flex flex-col items-center gap-2 px-4 py-6">
        {icon ? icon : <ExclamationCircleIcon size="large" />}
        <h1 className="text-xl font-bold">{title}</h1>
        {!!description && <p>{description}</p>}
      </div>
      {children}
    </div>
  );
}
