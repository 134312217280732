"use client";

import React, { type ComponentProps } from "react";
import { InfoCircleIcon } from "@nimbus-ds/icons";
import { type FieldError } from "react-hook-form";

import Select from "~/components/Select";

type Props = {
  label: string;
  error?: FieldError;
  warning?: FieldError;
  loading?: boolean;
} & ComponentProps<typeof Select>;

type SelectAppearance = "danger" | "neutral" | "success" | "warning";

const SelectField = React.forwardRef<HTMLSelectElement, Props>(
  ({ label, error, warning, loading, id, children, ...props }, ref) => {
    let appearance: SelectAppearance = "neutral";

    if (error || warning) {
      appearance = error ? "danger" : "warning";
    }

    return (
      <div className="my-2 flex w-full flex-col gap-2">
        <label
          className="block text-lg font-normal text-neutral-text-high"
          htmlFor={id}
        >
          {label}
        </label>

        {!loading && (
          <Select appearance={appearance} id={id} {...props} ref={ref}>
            {children}
          </Select>
        )}

        {loading && <Select.Skeleton width="100%" />}

        {error && (
          <span className="flex items-center gap-1 text-sm text-danger-text-low">
            <InfoCircleIcon size={"small"} /> {error.message}
          </span>
        )}

        {warning && (
          <span className="flex items-center gap-1 text-sm text-warning-text-low">
            <InfoCircleIcon size={"small"} /> {warning.message}
          </span>
        )}
      </div>
    );
  },
);

SelectField.displayName = "SelectField";

export default SelectField;
