"use client";

import React, { useEffect, useRef, type ComponentProps } from "react";
import lottie, { type AnimationItem } from "lottie-web";

type Props = {
  path: string;
  loop?: boolean;
  autoplay?: boolean;
} & ComponentProps<"div">;

export default function LottieAnimation({
  path,
  loop = true,
  autoplay = true,
  ...props
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      animationRef.current = lottie.loadAnimation({
        container: containerRef.current,
        renderer: "svg",
        loop,
        autoplay,
        path,
      });
    }

    return () => {
      if (animationRef.current) {
        animationRef.current.destroy();
      }
    };
  }, [path, loop, autoplay]);

  return <div ref={containerRef} {...props}></div>;
}
