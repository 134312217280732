"use client";

import {
  Checkbox as NimbusCheckbox,
  type CheckboxProps,
} from "@nimbus-ds/components";

import { NIMBUS_CHECKBOX_ID } from "../../tailwind.config";

export default function Checkbox({ ...props }: CheckboxProps) {
  return <NimbusCheckbox {...props} data-override-id={NIMBUS_CHECKBOX_ID} />;
}
