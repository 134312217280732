import { type ComponentProps, type ReactNode } from "react";
import clsx from "clsx";

type Props = {
  children: ReactNode;
  variant?: "primary" | "neutral";
} & ComponentProps<"button">;

export default function IconButton({
  variant,
  children,
  className,
  ...props
}: Props) {
  return (
    <button
      type="button"
      {...props}
      className={clsx(
        "flex h-14 w-14 items-center justify-center rounded-lg p-2",
        variant === "primary"
          ? "bg-primary-50 text-white"
          : "border border-neutral-interactive bg-neutral-surface text-neutral-text-high hover:border-neutral-interactive hover:bg-neutral-surface-highlight disabled:bg-neutral-surface-disabled",
        className,
      )}
    >
      {children}
    </button>
  );
}
