"use client";

import { type ComponentProps, type ReactNode } from "react";
import clsx from "clsx";

import Spinner from "./Spinner";

type Props = {
  children: ReactNode;
  variant?: "primary" | "neutral" | "danger";
  icon?: ReactNode;
  slim?: boolean;
  loading?: boolean;
} & ComponentProps<"button">;

export default function Button({
  children,
  variant = "primary",
  icon,
  slim = false,
  loading = false,
  disabled = false,
  ...props
}: Props) {
  const primary = variant === "primary";
  const neutral = variant === "neutral";
  const danger = variant === "danger";

  return (
    <button
      type="button"
      {...props}
      disabled={disabled || loading}
      className={clsx(
        "fit-content flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border align-middle font-medium focus:shadow-focus focus:outline-none active:shadow-none disabled:cursor-not-allowed",
        slim
          ? "h-8 px-2 text-sm leading-5 sm:h-10 sm:px-4 sm:py-2 sm:text-base"
          : "h-12 px-4 text-base leading-6 sm:h-14 sm:px-6 sm:py-4 sm:text-lg",
        primary &&
          "border-primary-interactive bg-primary-interactive text-neutral-background hover:bg-primary-interactive-hover active:bg-primary-interactive-pressed disabled:border-primary-surface-highlight disabled:bg-neutral-surface-disabled disabled:text-neutral-text-disabled",
        neutral &&
          "border-neutral-interactive bg-neutral-surface text-neutral-text-high hover:border-neutral-interactive-hover hover:bg-neutral-surface-highlight focus:border-primary-interactive focus:bg-neutral-surface focus:text-neutral-text-high active:border-neutral-interactive active:bg-neutral-interactive-pressed active:text-neutral-text-high disabled:border-neutral-surface-highlight disabled:bg-neutral-surface-disabled disabled:text-neutral-text-disabled",
        danger &&
          "border-danger-interactive bg-danger-surface text-danger-text-low hover:border-danger-interactive-hover hover:bg-danger-surface-highlight focus:bg-danger-surface active:bg-danger-interactive disabled:border-danger-surface-highlight disabled:bg-neutral-surface-disabled disabled:text-neutral-text-disabled",
        props.className,
      )}
    >
      {loading && (
        <Spinner size={slim ? "small" : "medium"} color="currentColor" />
      )}
      {icon}
      {children}
    </button>
  );
}
