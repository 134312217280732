import { type ComponentProps, type ReactNode } from "react";
import clsx from "clsx";

interface Props extends ComponentProps<"div"> {
  icon: ReactNode;
}

export default function IconBox({ icon, className }: Props) {
  return (
    <div
      className={clsx(
        "flex items-center justify-center rounded-lg bg-primary-surface p-2",
        className,
      )}
    >
      {icon}
    </div>
  );
}
