import { ExclamationCircleIcon, RedoIcon } from "@nimbus-ds/icons";

interface Props {
  title: string;
  retryText: string;
  onRetryClick: () => void;
}

function FetchErrorMessage({
  title,
  retryText,
  onRetryClick,
}: Readonly<Props>) {
  return (
    <div className="flex flex-col items-center gap-4 p-6 text-base">
      <ExclamationCircleIcon size="medium" />
      <span className="font-medium">{title}</span>
      <button
        onClick={onRetryClick}
        className="flex flex-row items-center gap-1 text-primary-interactive hover:text-primary-interactive-hover"
      >
        <RedoIcon size="small" />
        {retryText}
      </button>
    </div>
  );
}

export default FetchErrorMessage;
